import { useCallback, useContext } from "react";
import PropTypes from "prop-types";
import { useRouter } from "next/router";
import { AppContext } from "../../context";
import { CART_CATEGORY } from "../../Checkout/context/contstants";

const usePhysicalProductData = (product = {}) => {
  const router = useRouter();
  const { userHandle } = router.query;

  const { addToCart } = useContext(AppContext);

  const goToCheckout = useCallback(() => {
    router.push(`/${userHandle}/checkout`);
  }, [router, userHandle]);

  const submitProduct = useCallback(() => {
    const productData = {
      ...product,
      source: {
        category: CART_CATEGORY.digitalProducts,
        id: product.id,
        name: product.name,
      },
    };
    addToCart(CART_CATEGORY.digitalProducts, productData);
    goToCheckout();
  }, [addToCart, goToCheckout, product]);

  return {
    product,
    submitProduct,
  };
};

usePhysicalProductData.propTypes = {
  product: PropTypes.shape().isRequired,
};

export default usePhysicalProductData;
