import styled, { css } from "styled-components";
import { grey, red } from "@material-ui/core/colors";
import {
  LPText as LPT,
  CssButton as CBtn,
  CardTextArea as CTA,
} from "../../styles";

export const ProductWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 98%;
  min-height: 400px;
  margin: 0 auto;
  border-radius: 10px;
  margin-bottom: 3rem;
  cursor: pointer;
`;

export const ImgWrapper = styled.div`
  border: 1px solid var(--border-color);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  overflow: hidden;
  position: relative;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const ProductTitle = styled.p`
  color: var(--text-color);
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.1rem;
  margin: 0 0 0.5rem;
  text-transform: capitalize;

  ${(props) =>
    props.capitalize &&
    css`
      text-transform: capitalize;
    `}

  ${(props) =>
    props.bold &&
    css`
      font-weight: 600;
    `}
`;

export const ProductPrice = styled.p`
  color: var(--text-color);
  font-size: 0.9rem;
  font-weight: 600;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
`;

export const FlexFlow = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.column ? "column" : "row")};
  flex-wrap: ${(props) => (props.nowrap ? "nowrap" : "wrap")};
  flex-grow: ${(props) => (props.grow ? "1" : "0")};
  flex-shrink: ${(props) => (props.shrink ? "1" : "0")};
  flex-basis: ${(props) => props.basis || "auto"};
  margin: ${(props) => props.margin || "0"};
  padding: ${(props) => props.padding || "0"};
  align-items: ${(props) => props.align || "stretch"};
  justify-content: ${(props) => props.justify || "stretch"};
  ${(props) =>
    props.bordered &&
    css`
      border-color: ${grey[100]};
      border-width: 1px 0;
      border-style: solid;
    `};
  ${(props) =>
    props.splitSpace &&
    css`
      > * {
        ${!props.column &&
        css`
          margin-left: ${props.splitSpace} !important;
        `}
        ${props.column &&
        css`
          margin-top: ${props.splitSpace} !important;
        `}

      &:first-child {
          ${!props.column &&
          css`
            margin-left: 0 !important;
          `}
          ${props.column &&
          css`
            margin-top: 0 !important;
          `}
        }
      }
    `}
`;

export const LPText = styled(LPT)`
  font-size: 1rem;
  color: var(--text-color);
  font-weight: 600;
  margin-bottom: 0.5rem;
  margin-top: 1.5rem;
  padding: ${(props) => props.padding || 0};

  ${(props) =>
    props.uppercase &&
    css`
      text-transform: uppercase;
    `};

  ${(props) =>
    props.stroked &&
    css`
      text-decoration: line-through;
    `};

  ${(props) =>
    props.grey &&
    css`
      color: ${grey[600]};
    `};

  ${(props) =>
    props.noMargin &&
    css`
      margin: 0;
    `}

  ${(props) =>
    props.large &&
    css`
      font-size: 1.2em;
      font-weight: 200;
    `}

  ${(props) =>
    props.mid &&
    css`
      font-size: 0.8em;
    `}

  ${(props) =>
    props.small &&
    css`
      font-size: 0.8em;
      font-weight: 400;
    `}

  ${(props) =>
    props.bold &&
    css`
      font-weight: bold;
    `}

  ${(props) =>
    props.red &&
    css`
      color: ${red[600]};
    `}

  ${(props) =>
    props.centered &&
    css`
      align-items: center;
    `}
`;

export const inputStyles = {
  root: {
    color: "inherit",
  },
};

export const CardFooter = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-self: stretch;
  padding: 0.5em 0 0;
  z-index: 1;
  color: ${grey[500]};
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.4;

  .MuiButton-root {
    font-size: 0.8em !important;
  }

  @media (max-width: 425px) {
    background-color: white;
    flex: 0 0 auto;
    position: sticky;
    bottom: 0;
    user-select: none;
    padding: 0;
  }

  ${(props) =>
    props.sticky &&
    css`
      position: sticky;
      position: -webkit-sticky;
      bottom: 0;
    `};
`;

export const CssButton = styled(CBtn)`
  flex: 1 0 auto;
  ${(props) =>
    props.variant !== "outlined" &&
    css`
      background: ${grey[900]};
      color: ${grey[50]};
      border: none;
    `}
  .MuiButton-label {
    line-height: 1.4;
    font-size: 0.8rem;
  }
`;

export const CardTextArea = styled(CTA)`
  margin-bottom: 1rem;
  padding-bottom: 1rem;

  ${(props) =>
    props.bordered &&
    css`
      border: 1px 0 solid var(--border-color);
    `}

  ${(props) =>
    props.borderBottom &&
    css`
      border-bottom: 1px solid var(--border-color);
    `}

  ${(props) =>
    props.noMargin &&
    css`
      margin: 0;
    `}
`;

export const StyledDropProductWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 3rem;
`;

export const StyledDropProductImageWrapper = styled.div`
  border: 1px solid var(--border-color);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  overflow: hidden;
  position: relative;
  cursor: pointer;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const StyledDropProductInfoTitle = styled.div`
  display: grid;
  grid-template-columns: 100px 1fr 100px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  height: 35px;
`;

export const StyledDropProductStatus = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  color: ${grey[50]};
  font-size: 0.6rem;
  font-weight: 600;
  line-height: 1.4;
  text-transform: uppercase;
  background-color: ${grey[900]};

  ${(props) =>
    props.isStarted &&
    css`
      background-color: ${red[900]};
    `}
`;

export const StyledDropProductTimer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;

  background-color: ${grey[50]};
  color: ${grey[900]};
  font-size: 0.8rem;
  font-weight: 600;

  white-space: nowrap;
`;

export const StyledDropProductStockAmount = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  padding: 0.5rem 1rem;
  background-color: ${grey[50]};
  border-left: 1px solid ${grey[200]};
  color: ${red[900]};
  font-size: 0.6rem;
  text-transform: uppercase;
`;

export const StyledDropProductStockAmountLabel = styled.p`
  font-weight: 200;
  line-height: 1;
  margin: 0;
`;

export const StyledDropProductStockAmountItems = styled.p`
  font-weight: 600;
  line-height: 1;
  margin: 0;
`;

export const StyledDropProductFooter = styled.div`
  display: flex;
  flex-flow: row nowrap;
`;

export const StyledDropProductFooterText = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

export const StyledDropProductFooterActions = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  margin-left: auto;
`;

export const StyledDigitalProductFooter = styled.div`
  display: flex;
  flex-flow: row nowrap;
`;

export const StyledDigitalProductFooterText = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

export const StyledDigitalProductFooterActions = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  margin-left: auto;
`;

export const StyledInfo = styled.div`
  background-color: ${grey[900]};
  display: flex;
  flex-flow: row nowrap;
  padding: 1rem;
`;

export const StyledInfoIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  margin-right: 1rem;

  img {
    height: 30px;
  }
`;

export const StyledInfoContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 auto;
`;

export const StyledInfoTitle = styled.p`
  font-size: 0.9rem;
  font-weight: 600;
  color: ${grey[50]};
  margin: 0 0 0.25rem;
  line-height: 1;
`;

export const StyledInfoText = styled.p`
  font-size: 0.7rem;
  color: ${grey[50]};
  margin: 0;
  line-height: 1;
`;
