import { useCallback, useContext, useState } from "react";
import PropTypes from "prop-types";
import { useRouter } from "next/router";
import { AppContext } from "../../context";
import { CART_CATEGORY } from "../../Checkout/context/contstants";

const usePhysicalProductData = (product = {}) => {
  const router = useRouter();
  const { userHandle } = router.query;

  const { addToCart } = useContext(AppContext);

  const goToCheckout = useCallback(() => {
    router.push(`/${userHandle}/checkout`);
  }, [router, userHandle]);

  const [amountItems, setAmountItems] = useState(1);
  const [selectedSize, setSelectedSize] = useState(
    product?.variantInventory?.[0] || ""
  );

  const submitProduct = useCallback(() => {
    const variants = product.variantInventory || [];
    const selectedVariant = variants.find((v) => v.size === selectedSize.value);
    const productData = {
      ...product,
      variant_id: selectedVariant?.id,
      options: {
        size: selectedSize.value,
      },
      source: {
        category: CART_CATEGORY.products,
        id: product.id,
        name: product.name,
        size: selectedSize.value,
      },
    };
    addToCart(CART_CATEGORY.products, productData, { amount: amountItems });
    goToCheckout();
  }, [product, selectedSize.value, addToCart, amountItems, goToCheckout]);

  return {
    product,
    amountItems,
    setAmountItems,
    selectedSize,
    setSelectedSize,
    submitProduct,
  };
};

usePhysicalProductData.propTypes = {
  product: PropTypes.shape().isRequired,
};

export default usePhysicalProductData;
