import styled, { css } from "styled-components";
import { grey } from "@material-ui/core/colors";

export const GalleryWrapper = styled.div`
  display: block;
  box-sizing: border-box;
  margin: 35px 0 0;
  outline: none;
  width: 100%;

  ${(props) =>
    props.noMargin &&
    css`
      margin: 0;
    `}

  .swiper-container {
    margin-bottom: 0.5em;

    .swiper-button-prev,
    .swiper-button-next {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 30%;
      height: auto;
      margin: 0;
      padding: 1em;
      color: ${grey[200]};
      outline: none;
    }
    .swiper-button-prev {
      display: none;
      right: auto;
      left: 0;
      justify-content: flex-start;
    }
    .swiper-button-next {
      display: none;
      right: 0;
      left: auto;
      justify-content: flex-end;
    }
  }
  .swiper-slide {
    opacity: 0.4;
  }
  .swiper-slide-active {
    opacity: 1;
  }
  .swiper-slide-thumb-active {
    opacity: 1;
  }
`;

export const SlideWrapper = styled.div`
  aspect-ratio: 9/16;

  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  background-color: var(--background-color);
  border-radius: 0.5rem;
  min-width: 350px;
  min-height: 465px;

  ${(props) =>
    props.thumb &&
    css`
      min-width: 5rem;
      min-height: 4rem;
      transition: opacity 0.3s ease;
    `}

  ${(props) =>
    props.bgImage &&
    css`
      background-image: url("${props.bgImage}");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    `}
`;
