import { createContext, useMemo } from "react";
import PropTypes from "prop-types";
import useDropProductData from "./useDropProductData";
import usePhysicalProductData from "./usePhysicalProductData";
import useDigitalProductData from "./useDigitalProductData";

export const Context = createContext({});

export const Provider = ({ data, account, children }) => {
  const dropProductData = useDropProductData((data && data[0]) || {});
  const physicalProductData = usePhysicalProductData((data && data[0]) || {});
  const digitalProductData = useDigitalProductData((data && data[0]) || {});

  const contextData = useMemo(
    () => ({
      productItems: data,
      dropProductData,
      physicalProductData,
      digitalProductData,
      data,
      account,
    }),
    [account, data, digitalProductData, dropProductData, physicalProductData]
  );

  return <Context.Provider value={contextData}>{children}</Context.Provider>;
};

export const { Consumer } = Context;

Provider.defaultProps = {};

Provider.propTypes = {
  data: PropTypes.shape().isRequired,
  account: PropTypes.shape().isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
