import styled, { css } from "styled-components";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/styles";
import { grey, green, red, orange } from "@material-ui/core/colors";

export const ContentWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  background: var(--background-color);
  padding-top: 1rem;
  align-self: stretch;
  flex: 1 0 auto;
  overflow: hidden;
  position: relative;
  ${(props) =>
    props.nopadding &&
    css`
      padding: 0;
    `}
`;

export const ScrollingContent = styled.div`
  display: flex;
  //align-self: stretch;
  flex-flow: column nowrap;
  flex: 1 0 auto;
  padding: 1em 0;
  ${(props) =>
    props.nopadding &&
    css`
      padding: 0;
    `}
  ${(props) =>
    props.sidepadding &&
    css`
      padding: 0 1em;
    `}
 
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
`;

export const LPText = styled.p`
  color: var(--text-secondary-color);
  font-weight: 400;
  font-size: 1em;
  line-height: 1.6;
  margin: 0;

  a {
    color: var(--text-secondary-color);
  }

  ${(props) =>
    props.flex &&
    css`
      display: flex;
      align-items: center;
      > b {
        margin-right: 0.25em;
      }
    `}
  > svg {
    margin: 0 0.25em;
  }
  ${(props) =>
    props.w400 &&
    css`
      font-weight: 400;
    `}
  ${(props) =>
    props.w600 &&
    css`
      font-weight: 600;
    `}
  ${(props) =>
    props.w800 &&
    css`
      font-weight: 800;
    `}
  ${(props) =>
    props.mb1 &&
    css`
      margin-bottom: 1em;
    `}
  ${(props) =>
    props.mb2 &&
    css`
      margin-bottom: 2em;
    `}
  ${(props) =>
    props.m05 &&
    css`
      margin: 0.5rem;
    `}
  ${(props) =>
    props.marginleft &&
    css`
      margin-left: auto;
    `}
  ${(props) =>
    props.marginright &&
    css`
      margin-right: auto;
    `}
  ${(props) =>
    props.lowercase &&
    css`
      text-transform: lowercase;
    `}
  ${(props) =>
    props.nomargin &&
    css`
      margin-bottom: 0;
    `}
  ${(props) =>
    props.padded &&
    css`
      padding: 0.5em 1em;
    `}
  ${(props) =>
    props.centered &&
    css`
      text-align: center;
    `};
  ${(props) =>
    props.small &&
    css`
      font-size: 0.8em;
    `};
  ${(props) =>
    props.tiny &&
    css`
      font-size: 0.6em;
    `};
  ${(props) =>
    props.description &&
    css`
      font-size: 0.8em;
      margin-bottom: 0.5em;
      color: ${grey[400]};
    `};
  ${(props) =>
    props.error &&
    css`
      background-color: ${props.clear ? "transparent" : red[600]};
      border-radius: 0.25em;
      color: ${props.clear ? red[600] : grey[100]};
      padding: 0.5em 1em;
    `}
  ${(props) =>
    props.warning &&
    css`
      background-color: ${props.clear ? "transparent" : orange[600]};
      border-radius: 0.25em;
      color: ${props.clear ? orange[600] : grey[100]};
      padding: 0.5em 1em;
    `}
  ${(props) =>
    props.success &&
    css`
      background-color: ${props.clear ? "transparent" : green[600]};
      border-radius: 0.25em;
      color: ${props.clear ? green[600] : grey[100]};
      padding: 0.5em 1em;
    `}
  ${(props) =>
    props.active &&
    css`
      color: var(--brand-color);
    `}
`;

export const CssButton = withStyles({
  root: {
    flex: "1 0 auto",
    flexFlow: "row nowrap",
    "&:disabled": {
      "&.pay_btn": {
        background: grey[400],
        borderColor: grey[200],
        borderRadius: 0,
      },
    },
    "&:hover": {
      "&.pay_btn": {
        backgroundColor: grey[800],
      },
    },
    "&.pay_btn": {
      background: "var(--prime-gradient)",
      borderRadius: 0,
      color: grey[50],
      cursor: "pointer",
      position: "relative",
      padding: "1em",
    },
    "& .pay_amount": {
      position: "absolute",
      right: "1em",
    },
  },
  label: {
    lineHeight: 1,
    padding: "0.25em 1em",
  },
})(Button);

export const BodyWrapper = styled.div`
  padding: 0 1rem;
  margin-bottom: 4rem;
`;

export const CardTextArea = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-self: stretch;
  color: ${grey[500]};
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.4;
  ${(props) =>
    props.padded &&
    css`
      padding: 0.5em 1em;
    `}
`;

export const StyledProductAffiliate = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 1rem;
  padding: 0.25rem 0.5rem;
  margin: 0 1rem;
  background-color: var(--sc-card-background-color);
  border-radius: 0.5rem;
  border: 1px solid var(--sc-card-border-color);
  color: ${grey[50]};
`;

export const StyledProductAffiliateUsers = styled.div`
  display: flex;
  flex-flow: row nowrap;
`;

export const StyledProductAffiliateUser = styled.div`
  display: flex;
  flex-flow: row nowrap;
  z-index: 1;

  ${(props) =>
    props.secondary &&
    css`
      z-index: 0;
      margin-left: -1rem;
    `}
`;

export const StyledProductAffiliateDescription = styled.div`
  display: flex;
  flex-flow: row wrap;
  white-space: break-spaces;
  gap: 0 0.25rem;
  font-size: 0.9rem;
`;
