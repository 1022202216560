import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Thumbs, Controller, Pagination } from "swiper";
import ReactPlayer from "react-player";
import { GalleryWrapper, SlideWrapper } from "./styles";
import "swiper/swiper-bundle.css";
import { ReactPlayerWrapper } from "../ScallVideo/styles";
import { httpsUrl, isVideoType } from "../../helpers/data_helpers";

SwiperCore.use([Navigation, Thumbs, Controller]);

const Gallery = ({ images = [], noMargin }) => {
  const [slides, setSlides] = useState(images);

  useEffect(() => {
    if (images.length === 0) {
      setSlides([
        {
          id: "default",
          url: "/images/product_default_cover.png",
        },
      ]);
    }
  }, [images]);

  const slideComponents = slides.map((i) => {
    const mediaUrl = i?.url || "";
    const mediaHttps = httpsUrl(mediaUrl);
    const isVideo = isVideoType(mediaUrl);

    return (
      <SwiperSlide key={i.id}>
        {!isVideo && <SlideWrapper bgImage={mediaHttps} />}
        {isVideo && (
          <ReactPlayerWrapper>
            <ReactPlayer
              url={mediaHttps}
              playsinline
              autoplay
              playing
              loop
              muted
              width="100%"
              height="100%"
            />
          </ReactPlayerWrapper>
        )}
      </SwiperSlide>
    );
  });

  return (
    <GalleryWrapper noMargin={noMargin}>
      <Swiper
        id="main"
        initialSlide={0}
        spaceBetween={10}
        slidesPerView={1}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
      >
        {slideComponents}
      </Swiper>
    </GalleryWrapper>
  );
};

Gallery.defaultProps = {
  images: [],
  noMargin: false,
};

Gallery.propTypes = {
  images: PropTypes.arrayOf(PropTypes.shape()),
  noMargin: PropTypes.bool,
};

export default Gallery;
