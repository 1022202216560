import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";

const DropProductTimer = ({ timeTo, refreshCallback }) => {
  const [timerString, setTimerString] = useState("");

  useEffect(() => {
    const getTimeDifference = () => {
      let date = moment(timeTo).isValid() ? moment.utc(timeTo) : moment();
      const today = moment();

      if (today.isAfter(date)) {
        date = today;
        if (refreshCallback && typeof refreshCallback === "function") {
          refreshCallback();
        }
      }

      const totalSeconds = date.diff(today, "seconds");
      const seconds = totalSeconds % 60;
      const totalMinutes =
        totalSeconds >= seconds ? (totalSeconds - seconds) / 60 : 0;
      const minutes = totalMinutes % 60;
      const totalHours =
        totalMinutes >= minutes ? (totalMinutes - minutes) / 60 : 0;
      const hours = totalHours % 24;
      const days = totalHours >= hours ? (totalHours - hours) / 24 : 0;
      return [`${days}d`, `${hours}h`, `${minutes}m`, `${seconds}s`].join(
        " : "
      );
    };
    const timer = setTimeout(() => {
      setTimerString(getTimeDifference());
    }, 1000);
    return () => clearTimeout(timer);
  }, [refreshCallback, timeTo, timerString]);

  return timerString;
};

DropProductTimer.propTypes = {
  timeTo: PropTypes.string.isRequired,
  refreshCallback: PropTypes.func,
};

DropProductTimer.defaultProps = {
  refreshCallback: () => true,
};

export default DropProductTimer;
