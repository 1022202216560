import { useCallback, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import { isValidPhoneNumber } from "react-phone-number-input";
import axios from "axios";
import { useRouter } from "next/router";
import { DROP_PRODUCT_STATUSES } from "../../../constants/products";
import { AppContext } from "../../context";
import { CART_CATEGORY } from "../../Checkout/context/contstants";

const schema = yup.object().shape({
  firstName: yup.string().required("Oops! User name can`t be blank."),
  lastName: yup.string().required("Oops! User name can`t be blank."),
  primaryPhoneNumber: yup.mixed().test({
    name: "primaryPhoneNumber",
    message: "Phone number is not valid",
    test: (value) => isValidPhoneNumber(value),
  }),
});

const useDropProductData = (product = {}) => {
  const router = useRouter();
  const { userHandle } = router.query;

  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState({});
  const [usedFields, setUsedFields] = useState([]);
  const [validationError, setValidationError] = useState([]);
  const [amountItems, setAmountItems] = useState(1);
  const [selectedSize, setSelectedSize] = useState("");

  const { addToCart } = useContext(AppContext);
  const goToCheckout = useCallback(() => {
    router.push(`/${userHandle}/checkout`);
  }, [router, userHandle]);

  useEffect(() => {
    setStep(product.dropStatus === DROP_PRODUCT_STATUSES.SALING ? 2 : 0);
  }, [product]);

  const goNext = () => setStep(1);
  const goBack = () => setStep(0);

  const submitForm = useCallback(
    (onSuccess, onError) => {
      if (validationError?.length !== 0) return;
      const {
        firstName,
        lastName,
        zipCode,
        primaryPhoneNumber,
        // notes,
      } = formData;

      const inputData = {
        productId: product.id,
        firstName,
        lastName,
        zipCode,
        primaryPhoneNumber,
        // notes, TODO: add notes BE support
      };

      // MAKE THE AXIOS CALL
      axios
        .post("/api/p/dropProduct", { data: inputData })
        .then(() => {
          setFormData({});
          setUsedFields([]);
          goBack();
          if (onSuccess && typeof onSuccess === "function") {
            onSuccess();
          }
        })
        .catch((error) => {
          if (onError && typeof onError === "function") {
            onError(error?.response?.data || error?.message);
          }
          // eslint-disable-next-line no-console
          console.log(error?.response?.data || error?.message);
        });
    },
    [product, formData, validationError]
  );

  const setField = (name, value) => {
    setFormData((data) => ({ ...data, [name]: value }));
  };

  const handleFormValidation = useCallback(
    (fields) => {
      schema.validate(fields, { abortEarly: false }).then(
        () => {
          setValidationError([]);
        },
        (response) => {
          setValidationError(response.inner);
        }
      );
    },
    [setValidationError]
  );

  const hasErrorMessage = (name) => {
    const messages = [];
    const errors = validationError || [];
    errors.forEach((e) => {
      if (e.path === name && usedFields.includes(name)) {
        messages.push(e.message);
      }
    });
    return messages;
  };

  const updateUsedFields = (name) => {
    if (!usedFields.includes(name)) {
      usedFields.push(name);
      setUsedFields((fields) => [...fields, name]);
      handleFormValidation(formData);
    }
  };

  useEffect(() => {
    handleFormValidation(formData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleFormValidation(formData);
  }, [formData, handleFormValidation]);

  const submitProduct = useCallback(() => {
    const variants = product.variantInventory || [];
    const selectedVariant = variants.find((v) => v.size === selectedSize.value);
    const productData = {
      ...product,
      variant_id: selectedVariant?.id,
      options: {
        size: selectedSize.value,
      },
      source: {
        category: CART_CATEGORY.products,
        id: product.id,
        name: product.name,
        size: selectedSize.value,
      },
    };
    addToCart(CART_CATEGORY.products, productData, { amount: amountItems });
    goToCheckout();
  }, [product, selectedSize.value, addToCart, amountItems, goToCheckout]);

  return {
    step,
    goNext,
    product,
    formData,
    setField,
    submitForm,
    updateUsedFields,
    hasErrorMessage,
    amountItems,
    setAmountItems,
    selectedSize,
    setSelectedSize,
    isFormValid: validationError?.length === 0,
    isValidPurchase: selectedSize && amountItems,
    submitProduct,
  };
};

useDropProductData.propTypes = {
  product: PropTypes.shape().isRequired,
};

export default useDropProductData;
